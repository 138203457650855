<template>
<TermCondition/>
</template>

<script>
import TermCondition from './TermCondition'
export default {
    name: 'FreeUniversity',
    components:{
        TermCondition
    }
}
</script>
