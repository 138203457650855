<template>
<div class="desktop-height  ">

    <div class='pl-3 pr-3'>
        <h1 class='text-xl '>TERMS OF USE</h1>
        <div class='text-xs mt-2'>By accessing or using www.rap.land, the website, you are agreeing to be bound by the following terms. If you do not agree to these terms, please refrain from using our services.
        </div>
        <h1 class='text-xl mt-3'>BASIC TERMS </h1>
        <div class='pl-4'>
            <ol class="list-decimal text-xs">

                <li class=' mt-1'>You must be 13 years or older to use www.rap.land.</li>
                <li class=' mt-1'>You must not post nude, partially nude, or sexually suggestive content.</li>
                <li class=' mt-1'>You are responsible for any activity that occurs under your account.</li>
                <li class=' mt-1'>You are responsible for keeping your password secure at all times.</li>
                <li class=' mt-1'>You must not harass, impersonate, bully, or intimidate other www.rap.land users or anyone.</li>
                <li class=' mt-1'>You must not use our website for any illegal or unauthorized purposes. </li>
                <li class=' mt-1'>You are responsible for any content that you share on our website.</li>
                <li class=' mt-1'>You must not submit any malicious third party site links to any www.rap.land members. </li>
                <li class=' mt-1'>You must not transmit any worms or viruses or any code of a destructive nature. </li>
                <li class=' mt-1'>You must not copy, reproduce, transmit, modify or hack our website.</li>
                <li class=' mt-1'>You must not share any objectionable or harmful content with other www.rap.land users. </li>
                <li class=' mt-1'>You must not participate in any illegal conduct on our website.</li>
                <li class=' mt-1'>All subscription fees are non-refundable.</li>
            </ol>
        </div>
        <div class='text-xs mt-2'>Use the www.rap.land at your own risk. Although we prohibit malicious content on our website, please be aware that you may be exposed to malicious content from users directly or when they share a third party site. If you come across any harmful, malicious or objectionable content, you should send us an email and we will review the content within 24 hours and if that user is found guilty, the content will be removed immediately and that user will be banned from using our services. By using our website, you agree that www.rap.land is not responsible for any damages claims or other liability related to your use or the use of any www.rap.land user or third party website. Violations of these Terms will result in the termination of your account. You agree we may moderate www.rap.land use in our sole discretion.</div>
        <h1 class='text-xl mt-3 '>DISCLAIMER</h1>
        <p class='text-xs mt-1'>In regards to the accuracy, wholeness, security, reliability, availability and timeliness of www.rap.land; we have the right to make no guarantees and promises.SO PLEASE, USE www.rap.land AT YOUR OWN RISK!</p>
        <p class='text-xs mt-2'>We are not responsible for any wrongdoings or harm resulting from the following: user conduct, user content, any damages or loss of profit or other personal property. </p>

        <h1 class='text-xl mt-3 '>SUBSCRIPTION FEES</h1>
        <p class='text-xs mt-1'>Subscription fees are purchased from and billed by Stripe. Payments are payable and automatically charged at the time you place the order and will continue every year until you cancel your subscription. You must cancel your subscription before it renews to avoid the billing of the fees for the next subscription period. You can cancel your subscription at anytime via www.rap.land. You will not receive a refund for the funds you already paid for for your current subscription period and you will continue to receive the services ordered until the end of your current subscription period.</p>

        <h1 class='text-xl mt-3'>CONDITIONS</h1>
        <div class='pl-4'>
        <ol class="list-decimal text-xs">
            <li class=' mt-2'>We reserve the right to modify or terminate our Terms of Use for any reason and without a warning. </li>
            <li class=' mt-1'>We reserve the right to alter these Terms to our liking at any time.</li>
            <li class=' mt-1'>We reserve the right, in accordance to the necessary laws, to refuse service to anyone at any time.</li>
            <li class=' mt-1'>We may moderate and therefor remove content that we think violates these Terms at any time. </li>
            <li class=' mt-1'>We are not responsible for the actions from any user using the www.rap.land.</li>
            <li class=' mt-1'>We reserve the right to terminate any user for any reason and without a warning.</li>
        </ol>
        </div>
        <h1 class='text-xl mt-3'>COPYRIGHT</h1>
        <div class='pl-4'>
        <ol class="list-decimal text-xs">
            <li class=' mt-2'>You agree that you are solely responsible for your user content and we are not responsible or liable for any User Cont</li>
            <li class=' mt-1'>By submitting user content you grant us a non exclusive, royalty free, worldwide license to use your content to our liking.</li>
            <li class=' mt-1'>You agree that any feedback, ideas or suggestions that you provide about our services shall become sole property of www.rap.land. We love feedback that we can use to make our services better. Please do not share your ideas with us if you expect to be compensated or want to continue to own or claim rights to them.</li>
            <li class=' mt-1'>This Privacy Policy is a part of our Terms of Use, if you do not agree with the following practices, please do not use our mobile application.</li>
        </ol>
        </div>

        <h1 class='text-xl mt-3'>We collect the following information from users:</h1>
        <p class='text-xs mt-1'>Email addresses. Real names. Usernames. Usage history. Users location. User generated content.</p>

        <h1 class='text-xl mt-3'>We do not collect the following information: </h1>
        <span class='text-xs mt-1'>Credit card information. </span>

        <h1 class='text-xl mt-3'>Data Security</h1>
        <p class='text-xs mt-1'>We value your data and we know how important it is to you. We use many safeguards to protect your information, however we can not ensure that the information that you submit on the www.rap.land will be protected, so do so at your own risk. </p>

        <h1 class='text-xl mt-3'>International Users </h1>
        
        <ol class='text-xs' >
            <li class=' mt-2 '>www.rap.land, the website is hosted in the United States and intended for United States users under the United States law. </li>
            <li class=' mt-1'>By using our service you agree to be bound by the regulations and laws of the United States. </li>
            <li class=' mt-1 mb-4'>If there are any issues or if you have any questions regarding these terms you may contact us at raplandcharts@gmail.com</li>
        </ol>
      

    </div>
</div>
</template>

<script>

export default {
    name: 'TermCondition',
}
</script>